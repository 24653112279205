// MyDocument.js - Updated component with image buffer support
import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { latestListingsFinder, latestSalesFinder } from "configs/TableConstants";
import axios from "axios";
import classes from "./styles.module.css";
import {Button} from "antd"; // Make sure you have axios installed

// Create styles - keeping the original styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    paddingTop: "3vh",
    paddingBottom: "11vh",
    paddingHorizontal: 10,
  },
  titleWrapper: {
    textAlign: "center",
    height: "8vh",
  },
  titleName: {
    fontSize: 16,
    fontWeight: 800,
    marginTop: 2,
    color: "#1A3353",
  },
  titleDate: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 3,
    color: "#455560",
  },
  titleInfo: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 3,
    color: "#455560",
  },
  headingName: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 3,
    color: "#1A3353",
    textTransform: "capitalize",
  },
  headingInfo: {
    fontSize: 10,
    fontWeight: 500,
    marginTop: 3,
    color: "#1A3353",
  },
  headingPrice: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 3,
    color: "#455560",
  },
  heading: {
    fontSize: 10,
    fontWeight: 400,
    marginTop: 3,
  },
  comment: {
    fontSize: 10,
    fontWeight: 300,
    marginTop: 3,
    color: "#455560",
    textAlign: "justify",
  },
  commentNotAvailable: {
    fontSize: 10,
    fontWeight: 400,
    marginTop: 3,
    color: "#FFFFFF",
  },
  itemsWrapper: {
    marginLeft: 10,
  },
  itemsWrapperA: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  row: {
    width: "40%",
    marginHorizontal: 5,
  },
  priceWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "capitalize",
    width: "100%",
  },
  image: {
    height: 170,
    objectFit: "cover",
    marginTop: 10,
    marginBottom: 10,
  },
  titlePlaceholder: {
    height: "4vh",
  },
});

// Helper function to convert image to Base64
const fetchImageAsBuffer = async (url) => {
  try {
    // Ensure URL is absolute
    const imageUrl = url.startsWith('http') ? url : `${window.location.origin}${url}`;

    // Use axios to load the image as ArrayBuffer
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });

    // Convert ArrayBuffer to Base64
    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    // Determine MIME type based on URL
    let contentType = 'image/jpeg'; // Default
    if (url.toLowerCase().endsWith('.png')) contentType = 'image/png';
    if (url.toLowerCase().endsWith('.gif')) contentType = 'image/gif';
    if (url.toLowerCase().endsWith('.svg')) contentType = 'image/svg+xml';

    // Return complete Data URL
    return `data:${contentType};base64,${base64}`;
  } catch (error) {
    console.error("Error loading image:", error);
    return null;
  }
};

// Document component with buffered images
const MyBufferedDocument = ({ data, dateValue }) => {
  const {
    contact_name: name,
    contact_phone: phone,
    contact_email: email,
  } = data;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.titleWrapper}>
          <Text style={styles.titleName}>{data.title}</Text>
          <Text style={styles.titleDate}>{dateValue}</Text>
          <Text style={styles.titleInfo}>
            Contact: {name} {email ? `| ${email}` : ""}{" "}
            {phone ? `| ${phone}` : ""}
          </Text>
        </View>

        <View style={styles.itemsWrapperA}>
          {data.collection_properties.map((item, index) => {
            const { comment } = item;
            const {
              name,
              arv,
              parish,
              property_type,
              bedrooms,
              bathrooms,
              bufferedImage, // This is the pre-processed image
            } = item.property_database;

            // Original name handling logic
            let mutableName = <Text style={styles.headingName}>{name}</Text>;

            if (
              index % 2 === 0 &&
              index !== data.collection_properties.length - 1
            ) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const nextPropertyName =
                data.collection_properties[index + 1].property_database.name;
              const nextPropertyNameLength = nextPropertyName.length;

              if (propertyNameLength < nextPropertyNameLength) {
                let nameToAdd = nextPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd} aaa</Text>
                  </Text>
                );
              }
            } else if (index % 2 !== 0) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const prevPropertyName =
                data.collection_properties[index - 1].property_database.name;

              const prevPropertyNameLength = prevPropertyName.length;

              if (propertyNameLength < prevPropertyNameLength) {
                let nameToAdd = prevPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd}</Text>
                  </Text>
                );
              }
            }

            // Original price calculation logic
            let price = null;
            let bathRooms;
            let bedRooms = bedrooms ? `| ${bedrooms} Bedrooms` : null;
            if (bedrooms) {
              bathRooms = bathrooms ? ` ${bathrooms} Bathrooms` : null;
            } else {
              bathRooms = bathrooms ? `| ${bathrooms} Bathrooms ` : null;
            }

            if (
              item.property_database.property_database_listings.length > 0
            ) {
              const listingsRecord = latestListingsFinder(item.property_database.property_database_listings);
              price = `$${listingsRecord.price.toLocaleString()}`;
            } else if (
              item.property_database.sales.length > 0
            ) {
              const salesRecord = latestSalesFinder(item.property_database.sales);
              price = `$${salesRecord.price.toLocaleString()}`;
            }

            // Use buffered image if available, otherwise use default
            const defaultImage = "/img/nologo.png";
            const imgSrc = bufferedImage || defaultImage;

            return (
              <View
                key={item.id}
                style={{ ...styles.row, height: index > 3 ? "43vh" : "39vh" }}
              >
                {mutableName}
                <Text style={styles.headingInfo}>
                  ARV: {`$${arv.toLocaleString()}`} {bedRooms}
                  {bathRooms}
                </Text>
                <Image style={styles.image} src={imgSrc} />
                <View style={styles.priceWrapper}>
                  <Text style={styles.headingPrice}>
                    {property_type} | {parish}
                  </Text>
                  <Text style={styles.headingPrice}>{price}</Text>
                </View>

                <View>
                  <Text style={styles.comment}>{comment}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

// Original document component (kept for backward compatibility)
const MyDocument = (props) => {
  const { data, dateValue } = props;
  const {
    contact_name: name,
    contact_phone: phone,
    contact_email: email,
  } = data;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.titleWrapper}>
          <Text style={styles.titleName}>{data.title}</Text>
          <Text style={styles.titleDate}>{dateValue}</Text>
          <Text style={styles.titleInfo}>
            Contact: {name} {email ? `| ${email}` : ""}{" "}
            {phone ? `| ${phone}` : ""}
          </Text>
        </View>

        <View style={styles.itemsWrapperA}>
          {data.collection_properties.map((item, index) => {
            const { comment } = item;
            const {
              name,
              arv,
              parish,
              images,
              property_type,
              bedrooms,
              bathrooms,
            } = item.property_database;
            let mutableName = <Text style={styles.headingName}>{name}</Text>;

            if (
              index % 2 === 0 &&
              index !== data.collection_properties.length - 1
            ) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const nextPropertyName =
                data.collection_properties[index + 1].property_database.name;
              const nextPropertyNameLength = nextPropertyName.length;

              if (propertyNameLength < nextPropertyNameLength) {
                let nameToAdd = nextPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd} aaa</Text>
                  </Text>
                );
              }
            } else if (index % 2 !== 0) {
              const propertyName = name;
              const propertyNameLength = propertyName.length;
              const prevPropertyName =
                data.collection_properties[index - 1].property_database.name;

              const prevPropertyNameLength = prevPropertyName.length;

              if (propertyNameLength < prevPropertyNameLength) {
                let nameToAdd = prevPropertyName.slice(propertyNameLength);

                mutableName = (
                  <Text style={styles.headingName}>
                    {propertyName}{" "}
                    <Text style={{ color: "white" }}> {nameToAdd}</Text>
                  </Text>
                );
              }
            }
            let price = null;
            let bathRooms;
            let bedRooms = bedrooms ? `| ${bedrooms} Bedrooms` : null;
            if (bedrooms) {
              bathRooms = bathrooms ? ` ${bathrooms} Bathrooms` : null;
            } else {
              bathRooms = bathrooms ? `| ${bathrooms} Bathrooms ` : null;
            }

            if (
              item.property_database.property_database_listings.length > 0
            ) {
              const listingsRecord = latestListingsFinder(item.property_database.property_database_listings);
              price = `$${listingsRecord.price.toLocaleString()}`;
            } else if (
              item.property_database.sales.length > 0
            ) {
              const salesRecord = latestSalesFinder(item.property_database.sales);
              price = `$${salesRecord.price.toLocaleString()}`;
            }
            let imgSrc = "/img/nologo.png";
            if (images !== null && images.length > 0) {
              imgSrc = {
                uri: images[0],
                method: "GET",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                body: "",
              };
            }

            return (
              <View
                key={item.id}
                style={{ ...styles.row, height: index > 3 ? "43vh" : "39vh" }}
              >
                {mutableName}
                <Text style={styles.headingInfo}>
                  ARV: {`$${arv.toLocaleString()}`} {bedRooms}
                  {bathRooms}
                </Text>
                <Image style={styles.image} src={imgSrc} />
                <View style={styles.priceWrapper}>
                  <Text style={styles.headingPrice}>
                    {property_type} | {parish}
                  </Text>
                  <Text style={styles.headingPrice}>{price}</Text>
                </View>

                <View>
                  <Text style={styles.comment}>{comment}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

// Enhanced PDF Download component with image preloading
const BufferedPDFDownloadLink = ({ data, dateValue, fileName, children, buttonProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bufferedData, setBufferedData] = useState(null);

  useEffect(() => {
    const preloadImages = async () => {
      if (!data || !data.collection_properties) {
        setIsLoading(false);
        return;
      }

      // Create a deep copy of the data
      const newData = JSON.parse(JSON.stringify(data));

      // Process all images in parallel
      const promises = newData.collection_properties.map(async (item, index) => {
        const { images } = item.property_database;

        if (images && images.length > 0) {
          try {
            // Load and convert image to buffer
            const bufferedImage = await fetchImageAsBuffer(images[0]);
            if (bufferedImage) {
              // Add bufferedImage field to the data
              newData.collection_properties[index].property_database.bufferedImage = bufferedImage;
            }
          } catch (error) {
            console.error(`Error processing image for ${item.property_database.name}:`, error);
          }
        }
        return item;
      });

      // Wait for all image processing to complete
      await Promise.all(promises);
      setBufferedData(newData);
      setIsLoading(false);
    };

    preloadImages();
  }, [data]);

  if (isLoading) {
    return (
      <Button type="primary" disabled={true} className={classes.leftButton}>
        Download PDF
      </Button>
    );
  }

  return (
    <PDFDownloadLink
      document={<MyBufferedDocument data={bufferedData || data} dateValue={dateValue} />}
      fileName={fileName || `${data.title}-${Date.now()}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : children
      }
    </PDFDownloadLink>
  );
};

export { MyDocument, MyBufferedDocument, BufferedPDFDownloadLink };
export default MyDocument;