import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  BUTTON,
  COMMENT,
  IMAGE_LOAD,
  PROPERTY_DATABASE_CELL,
  latestListingsFinder,
  latestSalesFinder, getPrice,
} from "./TableConstants";
import { dateFormatHandler } from "./TableConstants";
import { SHOW_GROUP_BUTTON, GROUP_SALES_TAG } from "./TableConstants";

export const convertArvToNumber = (arv) => {
  return Number(arv.replace("$", "").replaceAll(",", ""));
};

export const tableEmptyText = {
  emptyText: (
    <p className="sidenav-heading1" style={{ marginTop: "30px" }}>
      No properties
    </p>
  ),
};
export const collectionColumns = [
  {
    title: "Title",
    render: (record) => (
      <p className="property-database-heading5">{record.title}</p>
    ),
  },
  {
    title: "Contact",
    render: (record) => (
      <p className="property-database-heading5">{record.contact_name}</p>
    ),
  },
  {
    title: "Created on",
    render: (record) => {
      const date = dateFormatHandler(new Date(record.created_on));
      return <p className="property-database-heading5">{date}</p>;
    },
  },
  {
    title: "Properties",
    sorter: (a, b) =>
      a.collection_properties.length - b.collection_properties.length,
    render: (record) => (
      <p className="property-database-heading5">
        {record.collection_properties.length}
      </p>
    ),
  },
];

export const collectionDetailsColumns = [
  {
    key: "image",
    editable: IMAGE_LOAD,
    width: "200px",
  },
  {
    key: "title",
    width: "45%",
    editable: COMMENT,
  },
  {
    key: "arv",
    title: "ARV",
    sorter: (a, b) => {
      return a.property_database.arv - b.property_database.arv;
    },
    render: (record) => {
      let arv = record.property_database.arv;
      if (!arv)  {
        return (
          <p className="property-database-heading5">N/A</p>
        );
      }
      return (
        <p className="property-database-heading5">${arv.toLocaleString()}</p>
      );
    },
  },
  {
    key: "price",
    title: "Price",
    sorter: (a, b) => {
      // Extract prices from property_database.property_database_listings
      const aListingData = a.property_database.property_database_listings.length > 0
        ? latestListingsFinder(a.property_database.property_database_listings)
        : null;
      const bListingData = b.property_database.property_database_listings.length > 0
        ? latestListingsFinder(b.property_database.property_database_listings)
        : null;

      const aListingPrice = aListingData ? aListingData.price : null;
      const bListingPrice = bListingData ? bListingData.price : null;

      // If no listing price, fallback to sales price
      const aSalesRecord = !aListingPrice && a.property_database.sales.length > 0
        ? latestSalesFinder(a.property_database.sales)
        : null;
      const bSalesRecord = !bListingPrice && b.property_database.sales.length > 0
        ? latestSalesFinder(b.property_database.sales)
        : null;

      const aPrice = aListingPrice || (aSalesRecord ? aSalesRecord.price : 0);
      const bPrice = bListingPrice || (bSalesRecord ? bSalesRecord.price : 0);

      // Sort prices (ascending order)
      return aPrice - bPrice;
    },
    render: (record) => {
      let price = null;
      if (record.property_database.property_database_listings.length > 0) {
        const listingData = latestListingsFinder(
          record.property_database.property_database_listings
        );
        price = listingData.price.toLocaleString();
      } else if (record.property_database.sales.length > 0) {
        const salesRecord = latestSalesFinder(record.property_database.sales);
        price = salesRecord.price.toLocaleString();
      }

      return <p className="property-database-heading5">${price}</p>;
    },
  },
  {
    key: "button",
    editable: BUTTON,
  },
];

export const propertyDatabaseColumns = [
  {
    key: "image",
    editable: IMAGE_LOAD,
    width: "200px",
  },
  {
    key: "title",
    title: "Property",
    editable: PROPERTY_DATABASE_CELL,
  },
  {
    key: "arv",
    title: "ARV",
    sorter: (a, b, sortOrder) => {
      const arvA = a.arv ? a.arv : sortOrder === 'ascend' ? Infinity : -Infinity;
      const arvB = b.arv ? b.arv : sortOrder === 'ascend' ? Infinity : -Infinity;
      return arvA - arvB;
    },
    render: (record) => {
      if (record.arv) {
        return (
          <p className="property-database-heading5" key={record.properties_id}>
            {`$${record.arv.toLocaleString()}`}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    key: "price",
    title: "Price",
    sorter: (a, b, sortOrder) => {
      const priceA = getPrice(a);
      const priceB = getPrice(b);
      const finalPriceA = priceA ? priceA : sortOrder === 'ascend' ? Infinity : -Infinity;
      const finalPriceB = priceB ? priceB : sortOrder === 'ascend' ? Infinity : -Infinity;
      return finalPriceA - finalPriceB;
    },
    render: (record) => {
      let price = getPrice(record);

      return (
        <p className="property-database-heading5" key={record.properties_id}>
          {price ? `$${price}` : "N/A"}
        </p>
      );
    },
  },
  {
    key: "button",
    editable: BUTTON,
  },
];

export const manualReviewColumn = [
  {
    key: "image",
    editable: IMAGE_LOAD,
    width: "200px",
  },
  {
    key: "title",
    title: "Property",
    render: (record) => {
      const bathRooms = record.bathrooms ? record.bathrooms : "N/A";
      const bedRooms = record.bedrooms ? record.bedrooms : "N/A";

      return (
        <div
          className="property-database-table-section2"
          key={record.properties_id}
        >
          <Link
            to={`${APP_PREFIX_PATH}/review/details/${record.properties_id}`}
            className="property-database-heading4"
          >
            <p className="property-database-heading4">{record.name}</p>
          </Link>
          <p className="property-database-heading5 text-format">
            {record.property_type} | {record.parish}
          </p>
          <p className="property-database-heading5">{record.address}</p>
          <p className="property-database-heading5">
            {bedRooms === 1 ? `${bedRooms} Bedroom` : `${bedRooms} Bedrooms`}{" "}
            {bathRooms === 1
              ? `${bathRooms} Bathroom`
              : `${bathRooms} Bathrooms`}
          </p>
          <p className="property-database-heading-red">
            {record.assessment_number ? "" : "Assessment Number missing"}
          </p>
          <p className="property-database-heading-red">
            {record.manual_review_listings[0].price ? "" : "Price missing"}
          </p>
        </div>
      );
    },
  },

  {
    key: "price",
    title: "Price",
    sorter: (a, b) => {
      if (
        a.manual_review_listings.length > 0 &&
        b.manual_review_listings.length > 0
      ) {
        return (
          a.manual_review_listings[0].price - b.manual_review_listings[0].price
        );
      }
    },
    render: (record) => {
      let price = null;

      if (record.manual_review_listings.length > 0) {
        price = record.manual_review_listings[0].price.toLocaleString();
      }

      return (
        <p className="property-database-heading5" key={record.properties_id}>
          {price ? `$${price}` : "N/A"}
        </p>
      );
    },
  },
];

export const salesColumns = [
  {
    key: "Button",
    editable: SHOW_GROUP_BUTTON,
    width: "60px",
  },
  {
    key: "Property",
    title: "Property",
    width: "30%",
    render: (record) => (
      <p className="property-database-heading8">{record.name}</p>
    ),
  },
  {
    key: "Address",
    title: "Address",
    width: "30%",
    render: (record) => (
      <p className="property-database-heading8">{record.address}</p>
    ),
  },
  {
    key: "ARV",
    title: "ARV",
    render: (record) => {
      if (record.arv) {
        return (
          <p className="property-database-heading8" key={record.properties_id}>
            {`$${record.arv.toLocaleString()}`}
          </p>
        );
      } else {
        return <></>;
      }
    },
  },

  {
    key: "Price",
    title: "Price",
    render: (record) => {
      let price = null;

      if (
        record.property_database_listings.length &&
        record.property_database_listings[0].price
      ) {
        price = record.property_database_listings[0].price.toLocaleString();
      } else if (record.sales.length && record.sales[0].price) {
        price = record.sales[0].price.toLocaleString();
      }

      return (
        <p className="property-database-heading5" key={record.properties_id}>
          {price ? `$${price}` : "N/A"}
        </p>
      );
    },
  },
  {
    key: "group_sales_tag",
    editable: GROUP_SALES_TAG,
  },
];

export const trendRepotColumns = [
  {
    title: "Title",
    render: (record) => (
      <p className="property-database-heading5">{record.title}</p>
    ),
  },
  {
    title: "Creator",
    render: (record) => (
      <p className="property-database-heading5">{record.contact_name}</p>
    ),
  },
  {
    title: "Created on",
    render: (record) => {
      const date = dateFormatHandler(new Date(record.created_on));
      return <p className="property-database-heading5">{date}</p>;
    },
  },
];
